/* This file contains all the constants for colors and font styles */

$body-font: Amiri, serif;
// Note that Gill Sans is the top of the stack and corresponds to what is used in Tufte's books
// However, it is not a free font, so if it is not present on the computer that is viewing the webpage
// The free Google 'Lato' font is used instead. It is similar.
$sans-font: "Gill Sans", "Gill Sans MT", "Lato", Arial, sans-serif;
$heading-font: Lato, sans-serif;
$code-font: Inconsolata, Courier, monospace; //Consolas, "Liberation Mono", Menlo, Courier, monospace;
$url-font: "Lucida Console", "Lucida Sans Typewriter", Monaco, "Bitstream Vera Sans Mono", monospace;
$text-color: #111;
$bg-color: #fffff8;
$contrast-color: #001663; //#a00000
$border-color: #333333;
$link-style: color; // choices are 'color' or 'underline'. Default is color using $contrast-color set above



